import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/seo';
// import LocalizedLink from "../components/localizedLink"
import useTranslations from "../components/useTranslations"
import TitlePage from "../components/TitlePage";
import PostItem from "../components/PostItem";

import * as S from '../components/ListWrapper/styled';
// import {useLocale} from "../hooks/locale";

const IndexPage = ({data: {allMarkdownRemark}}) => {
    const {hello} = useTranslations()
    const postList = allMarkdownRemark.edges;
    return (
        <div className="homepage">
            <SEO title="Home" />
            <TitlePage text={hello}/>
            {/*<p>{subline}</p>*/}
            <hr style={{margin: `2rem 0`}}/>
            {/*<h2>*/}
            {/*    <strong>{latestPosts}</strong>*/}
            {/*</h2>*/}

            <br/>

            <S.ListWrapper>
                {postList.map(({node: post}) => (
                    <PostItem
                        slug={`/${post.parent.relativeDirectory}`}
                        // background={background}
                        // category={category}
                        date={post.frontmatter.date}
                        // timeToRead={timeToRead}
                        title={post.frontmatter.title}
                        // description={description}
                        // image={image}
                        key={`${post.frontmatter.title}-${post.fields.locale}`}
                    />
                ))}
            </S.ListWrapper>

            <br/>

            {/*<LocalizedLink to={`/blog/`}>{allPosts}</LocalizedLink>*/}
        </div>
    )
}

// export const queryHello = graphql`
//     query Home($locale: String) {
//         file(name: { eq: $locale }) {
//             childTranslationsJson {
//                 hello
//             }
//         }
//     }
// `

export const query = graphql`
    query Index($locale: String, $dateFormat: String) {
        allMarkdownRemark(
            sort: {fields: frontmatter___date, order: DESC},
            filter: {fields: {locale: {eq: $locale}}, fileAbsolutePath: {regex: "/(blog)/.*.md$/"}}
        ) {
            edges {
                node {
                    frontmatter {
                        date(formatString: $dateFormat)
                        title
                    }
                    fields {
                        locale
                    }
                    parent {
                        id
                        ... on File {
                            id
                            relativeDirectory
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage